<template>
  <div></div>
</template>

<script>
import store from "node-back-client/src/store/index";
export default {
  name: "OAuth",
  created() {
    let token = store.state.token;
    let {redirect_uri,state,response_type} = this.$route.query;
    window.location.href = `${redirect_uri}?code=${token}&state=${state}&response_type=${response_type}`;
  }
}
</script>

<style scoped>

</style>